import * as React from 'react'
import styled from 'styled-components'
import { Title } from '@awdis/components'

import Layout, { LayoutData } from '../../components/Layout'
import Seo from '../../components/Seo'
import ContactRedirectItems from '../../components/ContactRedirectItems'

const PageDescription = styled.p`
  text-align: center;
  margin-top: 32px;
`

type ContactProps = {
  pageContext: {
    layoutData: LayoutData
    page: {
      title: string
      url: string
    }
  }
}

const defaultProps = {}

const Contact = function Contact({
  pageContext: {
    layoutData,
    layoutData: { brandDescription },
    page,
  },
}: ContactProps) {
  return (
    <Layout data={layoutData}>
      <Seo
        title={page.title}
        description={brandDescription}
        type="website"
        pathname={page.url}
      />
      <Title as="h2" size="small" align="center">
        Contact
      </Title>

      <PageDescription>
        At AWDis, instead of selling directly, our products can be purchased
        through our exclusive distribution network. To help point you in the
        right direction and find the right solution for your needs, tell us
        which option best describes you:
      </PageDescription>

      <ContactRedirectItems />
    </Layout>
  )
}

Contact.defaultProps = defaultProps

export default Contact
